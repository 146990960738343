import React from "react"
import { useTranslation } from "react-i18next"

import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import BookIcons1 from "../../static/images/Icons/book-icons1.svg"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default function Share({ title, url, twitterHandle, tags }) {
  const [t] = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="default"
        className="shareDialogButton"
        startIcon={
          <img className="shareBookImageIcons" alt="book" src={BookIcons1} />
        }
      >
        {t("book.share")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("book.share")}
        </DialogTitle>
        <DialogContent dividers>
          <FacebookShareButton url={url}>
            <FacebookIcon size={40} round={true} />
            <Typography
              component="span"
              variant="subtitle1"
              className="react-share__ShareButton-label"
            >
              Facebook
            </Typography>
          </FacebookShareButton>

          <TwitterShareButton
            url={url}
            title={title}
            via={twitterHandle}
            hashtags={tags}
          >
            <TwitterIcon size={40} round={true} />
            <Typography
              component="span"
              variant="subtitle1"
              className="react-share__ShareButton-label"
            >
              Twitter
            </Typography>
          </TwitterShareButton>

          <EmailShareButton url={url}>
            <EmailIcon size={40} round={true} />
            <Typography
              component="span"
              variant="subtitle1"
              className="react-share__ShareButton-label"
            >
              Email
            </Typography>
          </EmailShareButton>

          <TelegramShareButton url={url} title={title}>
            <TelegramIcon size={40} round={true} />
            <Typography
              component="span"
              variant="subtitle1"
              className="react-share__ShareButton-label"
            >
              Telegram
            </Typography>
          </TelegramShareButton>

          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={40} round={true} />
            <Typography
              component="span"
              variant="subtitle1"
              className="react-share__ShareButton-label"
            >
              Whatsapp
            </Typography>
          </WhatsappShareButton>
        </DialogContent>
      </Dialog>
    </>
  )
}
