import React, { useState, useEffect, useContext, useRef } from "react"
import axios from "axios"
import Img from "gatsby-image"
import Slider from "react-slick"
import PropTypes from "prop-types"
import { SnackbarProvider } from "notistack"
import "react-h5-audio-player/lib/styles.css"
import { useTranslation } from "react-i18next"
import { graphql, Link, navigate } from "gatsby"
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player"
import SiteContext from "../context/siteContext"
import { Player } from "video-react"

import {
  Container,
  useTheme,
  makeStyles,
  Typography,
  Grid,
  Box,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Button,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  Collapse,
  useMediaQuery,
  TextField,
  FormControl,
  ButtonGroup,
  CircularProgress,
  Avatar,
} from "@material-ui/core"

import SEO from "../components/seo"
import Share from "../components/share"
import Sigin from "../components/signin"
import Rating from "@material-ui/lab/Rating"
import MuiAlert from "@material-ui/lab/Alert"
import Favorite from "../components/favorite-btn"
import SigninForm from "../components/signin-form"
import Breadcrumbs from "../components/breadcrumbs"
import SingleSlide from "../components/single-slide"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"
import PauseIcon from "@material-ui/icons/Pause"
import RemoveIcon from "@material-ui/icons/Remove"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
import FooterCategory2 from "../../static/images/Icons/CategoryIcons2.svg"
import FooterCategory3 from "../../static/images/Icons/CategoryIcons3.svg"

const BookTemplate = ({ data, pageContext, props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const VideoTest = useRef(null)

  const [t, i18n] = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [countItem, setCountItem] = useState({
    count: 1,
  })
  const [checkedMoreLess, setCheckedMoreLess] = useState(false)
  const [valueMoreLess, setValueMoreLess] = useState(t("book.showMore"))
  const [yourComment, setYourComment] = useState("")
  const [allComment, setAllComment] = useState([])
  // const [allRatings, setAllRatings] = useState([])
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [rateValues, setRateValues] = useState(0)
  const [commentOffset, setCommentOffset] = useState(0)
  const [signFormOpen, setSignFormOpen] = useState(false)
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const { strapiId, title, pLang, rating, image, allCategories } = pageContext
  const { isActiveCart, setIsActiveCart, setCartItemContentLenght } = useContext(SiteContext)

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `book`, `/${i18n.language}/books/${strapiId}`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `book`, `/${i18n.language}/books/${strapiId}`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/books/${strapiId}`
      }
      // document.getElementById("introVideo").contentWindow.location.reload()
    })
  }, [i18n, pLang, strapiId])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const cats = allCategories.map(item => {
    const titleForLang =
      i18n.language === "ar"
        ? item.title_ar
        : i18n.language === "tr"
        ? item.title_tr
        : i18n.language === "en" && item.title
    return {
      text: titleForLang,
      link: `${i18n.language}/categories/${item.id}`,
    }
  })
  const titleForLang =
    i18n.language === "ar"
      ? data.strapiBooks.title_ar
      : i18n.language === "tr"
      ? data.strapiBooks.title_tr
      : i18n.language === "en" && data.strapiBooks.title

  const breadcrumbs = [
    ...cats,
    {
      text: titleForLang,
    },
  ]

  let bookItems =
    data.strapiBooks.book_items !== 0 ? data.strapiBooks.book_items.filter(item => item.type === "paper") : ""
  let bookItem = bookItems.length > 0 ? bookItems[0] : {}
  const { price, discountPrice } = bookItem

  let bookPrice = price > 0 ? price * currencyRateState : 0
  let bookDiscountPrice = discountPrice !== null && discountPrice > 0 ? discountPrice * currencyRateState : 0

  const handleCommentChange = event => {
    setYourComment(event.target.value)
  }
  const handleClickBadgeReduce = () => {
    setCountItem({
      count: countItem.count + 1,
    })
  }
  const handleClickBadgeIncrease = () => {
    setCountItem({
      count: Math.max(countItem.count - 1, 0),
    })
  }
  const handleCloseSnackBar = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
  }
  const handleAddToCard = () => {
    if (jwtForProfile) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/add-cart-item`,
          {
            quantity: countItem.count,
            book: data.strapiBooks.strapiId ? data.strapiBooks.strapiId : data.strapiBooks.id,
            format: "paper",
          },
          { headers: { Authorization: `Bearer ${jwtForProfile}` } }
        )
        .then(() => {
          setOpenSnackbar(true)
          setTimeout(() => {
            setOpenSnackbar(false)
          }, 1600)
          if (!isActiveCart) {
            setIsActiveCart(true)
          }
          axios
            .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
              },
            })
            .then(res => {
              setCartItemContentLenght(res.data.items.length)
            })
        })
    } else {
      setSignFormOpen(true)
    }
  }
  const handleQuickPurchase = () => {
    if (jwtForProfile) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/add-cart-item`,
          {
            quantity: countItem.count,
            book: data.strapiBooks.strapiId ? data.strapiBooks.strapiId : data.strapiBooks.id,
            format: "paper",
          },
          { headers: { Authorization: `Bearer ${jwtForProfile}` } }
        )
        .then(() => {
          setTimeout(() => {
            // TODO: if new cart page dos'nt wanted change this to `checkout`
            navigate(`/${i18n.language}/cart-items`)
          }, 2000)
          setOpenSnackbar(true)
          setTimeout(() => {
            setOpenSnackbar(false)
          }, 1600)
          if (!isActiveCart) {
            setIsActiveCart(true)
          }
          axios
            .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
              },
            })
            .then(res => {
              setCartItemContentLenght(res.data.items.length)
            })
        })
    } else {
      setSignFormOpen(true)
    }
  }
  const signFormClose = () => {
    setSignFormOpen(false)
  }
  const handleCloseAddToCard = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }
  const handleChangeMoreLess = () => {
    setCheckedMoreLess(prev => !prev)
  }
  const handleClickMoreLess = () => {
    setCheckedMoreLess(prev => !prev)
    if (checkedMoreLess) {
      setValueMoreLess(t("book.showMore"))
    } else {
      setValueMoreLess(t("book.showLess"))
    }
  }
  const handleLoadMoreComments = () => {
    setCommentOffset(commentOffset + 10)
  }

  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isMobile = useMediaQuery("(max-width:600px)")

  // let slideItems
  // if (isTablet) {
  //   slideItems = 3
  // } else if (isMobile) {
  //   slideItems = 1
  // } else {
  //   slideItems = 5
  // }

  const settings = {
    className: "center",
    infinite: true,
    // slidesToShow: slideItems,
    swipeToSlide: true,
  }

  let jwtForProfile
  let idForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
    idForProfile = windowGlobal.sessionStorage.getItem("userId")
  }

  useEffect(() => {
    setLoadingMore(true)
    setDisabledBtn(true)
    axios
      .get(`${process.env.GATSBY_API_URL}/get-comments-by-book/${data.strapiBooks.strapiId}/${commentOffset}/10`)
      .then(res => {
        const allComments = res.data
        const filterCommentsByStatus = allComments.filter(item => item.status !== "pending")
        setAllComment([...allComment, ...filterCommentsByStatus])
        setLoadingMore(false)
        allComment.length !== 0 && setDisabledBtn(false)
        allComment.length === 0 && setDisabledBtn(true)
      })
    // axios
    // .get(
    //   `${process.env.GATSBY_API_URL}/ratings`
    // )
    // .then(res => {
    //   const allRatings = res.data
    //   const filterRatingsByBookId = allRatings.filter(item => item.book.id !== strapiId)
    //   setAllRatings([
    //     ...allRatings,
    //     filterRatingsByBookId
    //   ])
    //   setLoadingMore(false)
    //   allComment.length !== 0 && setDisabledBtn(false)
    //   allComment.length === 0 && setDisabledBtn(true)
    // })
  }, [commentOffset, /* allComment, */ data.strapiBooks.strapiId])

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    axios
      .post(
        `${process.env.GATSBY_API_URL}/add-comment`,
        {
          comment: yourComment,
          bookId: data.strapiBooks.strapiId,
          user: idForProfile,
          rate: rateValues,
        },
        { headers: { Authorization: `Bearer ${jwtForProfile}` } }
      )
      .then(() => {
        setLoading(false)
        setYourComment("")
        setRateValues(0)
        setOpenAlertSuccess(true)
        setTimeout(() => {
          setOpenAlertSuccess(false)
        }, 2000)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const bookTitle =
    i18n.language === "en"
      ? data.strapiBooks.title
      : i18n.language === "ar"
      ? data.strapiBooks.title_ar
      : i18n.language === "tr"
      ? data.strapiBooks.title_tr
      : ""

  const bookUrl = `https://gwthani.com/${i18n.language}/books/${data.strapiBooks.strapiId}`
  const twitterHandle = "_Gwthani"

  const handleOpenSnackbar = () => {
    setOpenSuccessSnackbar(true)
    setTimeout(() => {
      setOpenSuccessSnackbar(false)
    }, 1600)
  }
  const handleCloseAddToCardSlides = () => {
    setOpenSuccessSnackbar(false)
  }
  const handleDeleteItem = commentId => {
    axios
      .delete(`${process.env.GATSBY_API_URL}/comments/${commentId}`, {
        headers: { Authorization: `Bearer ${jwtForProfile}` },
      })
      .then(res => {
        let deletedComment
        deletedComment = allComment.filter(item => item.id !== commentId)
        setAllComment(deletedComment)
      })
  }

  useEffect(() => {
    if (videoUrl) {
      document.getElementById("introVideo").src = `https://www.youtube.com/embed/${videoUrl}`
    }
  }, [i18n.language])

  const introAudioForLang =
    i18n.language === "ar"
      ? data.strapiBooks.introAudio && data.strapiBooks.introAudio.publicURL
      : i18n.language === "tr"
      ? data.strapiBooks.introAudio_ar && data.strapiBooks.introAudio_ar.publicURL
      : i18n.language === "en" && data.strapiBooks.introAudio_tr && data.strapiBooks.introAudio_tr.publicURL

  const firstNameForLang =
    i18n.language === "ar" && data.strapiBooks.author
      ? data.strapiBooks.author.firstName_ar
      : i18n.language === "tr" && data.strapiBooks.author
      ? data.strapiBooks.author.firstName_tr
      : i18n.language === "en" && data.strapiBooks.author && data.strapiBooks.author.firstName

  const lastNameForLang =
    i18n.language === "ar" && data.strapiBooks.author
      ? data.strapiBooks.author.lastName_ar
      : i18n.language === "tr" && data.strapiBooks.author
      ? data.strapiBooks.author.lastName_tr
      : i18n.language === "en" && data.strapiBooks.author && data.strapiBooks.author.lastName

  const firstNameCheck = firstNameForLang ? firstNameForLang : " "
  const lastNameCheck = lastNameForLang ? lastNameForLang : " "

  const videoUrl =
    i18n.language === "en"
      ? data.strapiBooks.introVideo && data.strapiBooks.introVideo.substr(32)
      : i18n.language === "ar"
      ? data.strapiBooks.introVideo_ar && data.strapiBooks.introVideo_ar.substr(32)
      : i18n.language === "tr"
      ? data.strapiBooks.introVideo_tr && data.strapiBooks.introVideo_tr.substr(32)
      : 0

  const introText =
    i18n.language === "tr"
      ? data.strapiBooks.introText_tr &&
        data.strapiBooks.introText_tr.length >= 200 &&
        data.strapiBooks.introText_tr.replace(/<[^>]*>?/gm, "")
      : i18n.language === "en"
      ? data.strapiBooks.introText &&
        data.strapiBooks.introText.length >= 200 &&
        data.strapiBooks.introText.replace(/<[^>]*>?/gm, "")
      : i18n.language === "ar"
      ? data.strapiBooks.introText_ar &&
        data.strapiBooks.introText_ar.length >= 200 &&
        data.strapiBooks.introText_ar.replace(/<[^>]*>?/gm, "")
      : 0

  const shortIntroText =
    i18n.language === "tr"
      ? data.strapiBooks.introText_tr &&
        data.strapiBooks.introText_tr.length < 200 &&
        data.strapiBooks.introText_tr.replace(/<[^>]*>?/gm, "")
      : i18n.language === "en"
      ? data.strapiBooks.introText &&
        data.strapiBooks.introText.length < 200 &&
        data.strapiBooks.introText.replace(/<[^>]*>?/gm, "")
      : i18n.language === "ar"
      ? data.strapiBooks.introText_ar &&
        data.strapiBooks.introText_ar.length < 200 &&
        data.strapiBooks.introText_ar.replace(/<[^>]*>?/gm, "")
      : 0


  return (
    <>
      <SEO title={title} lang={i18n.language} />
      <SigninForm isOpen={signFormOpen} onClose={signFormClose} />
      <Breadcrumbs links={breadcrumbs} />

      <Container className={`${classes.fullWidth} ${classes.bookBg}`}>
        <Container className={classes.bookInnerContainer}>
          <Grid container>
            {isMobile && (
              <Typography variant="h3" component="h2" className={classes.bookTitle}>
                {i18n.language === "en" && data.strapiBooks.title}
                {i18n.language === "ar" && data.strapiBooks.title_ar}
                {i18n.language === "tr" && data.strapiBooks.title_tr}
              </Typography>
            )}
            <Grid item xs={12} sm={12} md={4} className={classes.textCenterMobile}>
              {/* {data.strapiBooks.image.publicURL} */}
              {/* <img
                src={data.strapiBooks.image && data.strapiBooks.image.publicURL}
                className={image ? `${classes.bookImage} ${classes.bookImageBorder}` : classes.bookImage}
                style={{width: "280px"}}
              /> */}
              <Img
                fixed={data.strapiBooks.image && data.strapiBooks.image.childImageSharp.fixed}
                className={image ? `${classes.bookImage} ${classes.bookImageBorder}` : classes.bookImage}
              />
              <div style={{ width: "100%" }}>
                <Box display="flex" p={1} bgcolor="background.paper" className={classes.boxButtonsLeftPadding}>
                  <Box p={1}>
                    <Share title={bookTitle} url={bookUrl} twitterHandle={twitterHandle} />
                  </Box>
                  <Box p={1}>
                    <Favorite id={data.strapiBooks.strapiId} />
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className={classes.bookRating}>
              {!isMobile && (
                <Typography variant="h5" component="h2" className={classes.bookTitle}>
                  {i18n.language === "en" && data.strapiBooks.title}
                  {i18n.language === "ar" && data.strapiBooks.title_ar}
                  {i18n.language === "tr" && data.strapiBooks.title_tr}
                </Typography>
              )}
              <Grid container className={classes.bookRatingDiv}>
                <Grid item xs={12} sm={12} md={7}>
                  <Typography variant="subtitle1" component="div" className={classes.bookRatingText}>
                    {t("author.rate")}
                  </Typography>
                  {rating ? (
                    <Rating name="half-rating-read" defaultValue={rating} precision={0.5} readOnly />
                  ) : (
                    <Rating name="half-rating-read" defaultValue={0} precision={0.5} readOnly />
                  )}
                  <Box className={classes.priceBox}>
                    {bookDiscountPrice > 0 && bookPrice ? (
                      <>
                        <Typography variant="body1" component="del" className={classes.discPrice}>
                          {parseFloat(bookPrice).toFixed(2)}
                          {bookPrice && currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>}
                        </Typography>
                        <Typography variant="body1" component="span" className={classes.activePrice}>
                          {parseFloat(bookDiscountPrice).toFixed(2)}
                          {bookDiscountPrice && currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>}
                        </Typography>
                      </>
                    ) : bookPrice ? (
                      <Typography variant="body1" component="span" className={classes.activePrice}>
                        {parseFloat(bookPrice).toFixed(2)}
                        {bookPrice && currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>}
                      </Typography>
                    ) : (
                      <Typography variant="body1" component="span" className={classes.activePrice}>
                        {parseFloat(bookDiscountPrice).toFixed(2)}
                        {bookDiscountPrice && currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>}
                      </Typography>
                    )}
                  </Box>
                  <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableBody>
                        {data.strapiBooks.author && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.author")}
                            </TableCell>
                            <TableCell align="center">
                              <Link
                                className={classes.tableLink}
                                to={`/${i18n.language}/authors/${
                                  data.strapiBooks.author && data.strapiBooks.author.id
                                }`}
                              >
                                {data.strapiBooks.author && firstNameCheck} {data.strapiBooks.author && lastNameCheck}
                              </Link>
                            </TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.investigator && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.investigator")}
                            </TableCell>
                            <TableCell align="center">
                              {i18n.language === "en" &&
                                data.strapiBooks.investigator.firstName &&
                                data.strapiBooks.investigator.firstName}{" "}
                              {i18n.language === "en" &&
                                data.strapiBooks.investigator.lastName &&
                                data.strapiBooks.investigator.lastName}
                              {i18n.language === "ar" &&
                                data.strapiBooks.investigator.firstName_ar &&
                                data.strapiBooks.investigator.firstName_ar}{" "}
                              {i18n.language === "ar" &&
                                data.strapiBooks.investigator.lastName_ar &&
                                data.strapiBooks.investigator.lastName_ar}
                              {i18n.language === "tr" &&
                                data.strapiBooks.investigator.firstName_tr &&
                                data.strapiBooks.investigator.firstName_tr}{" "}
                              {i18n.language === "tr" &&
                                data.strapiBooks.investigator.lastName_tr &&
                                data.strapiBooks.investigator.lastName_tr}
                            </TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "en" && data.strapiBooks.subject && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.subject")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.subject}</TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "ar" && data.strapiBooks.subject_ar && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.subject")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.subject_ar}</TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "tr" && data.strapiBooks.subject_tr && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.subject")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.subject_tr}</TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "en" && data.strapiBooks.cover && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.coverType")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.cover}</TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "ar" && data.strapiBooks.cover_ar && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.coverType")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.cover_ar}</TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "tr" && data.strapiBooks.cover_tr && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.coverType")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.cover_tr}</TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.pagesNumber !== null && data.strapiBooks.pagesNumber > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.pageCount")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.pagesNumber}</TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.publicationYear && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.publicationYear")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.publicationYear}</TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.publisher && data.strapiBooks.publisher.name && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.publisher")}
                            </TableCell>
                            <TableCell align="center">
                              <Link
                                className={classes.tableLink}
                                to={`/${i18n.language}/publishers/${data.strapiBooks.publisher.id}`}
                              >
                                {i18n.language === "en" && data.strapiBooks.publisher.name}
                                {i18n.language === "ar" && data.strapiBooks.publisher.name_ar}
                                {i18n.language === "tr" && data.strapiBooks.publisher.name_tr}
                              </Link>
                            </TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.ISBN && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              ISBN
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.ISBN}</TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.weight > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.weight")}
                            </TableCell>
                            <TableCell align="center">{data.strapiBooks.weight}</TableCell>
                          </TableRow>
                        )}
                        {data.strapiBooks.height && data.strapiBooks.width && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {t("book.dimensions")}
                            </TableCell>
                            <TableCell align="center">
                              <div className="bookTableDimentions" style={{ direction: "ltr" }}>
                                {data.strapiBooks.width + " x " + data.strapiBooks.height + " cm"}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                        {i18n.language === "en" &&
                          data.strapiBooks.additional_info &&
                          data.strapiBooks.additional_info.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item.name}
                              </TableCell>
                              <TableCell align="center">{item.value}</TableCell>
                            </TableRow>
                          ))}
                        {i18n.language === "ar" &&
                          data.strapiBooks.additional_info_ar &&
                          data.strapiBooks.additional_info_ar.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item.name}
                              </TableCell>
                              <TableCell align="center">{item.value}</TableCell>
                            </TableRow>
                          ))}
                        {i18n.language === "tr" &&
                          data.strapiBooks.additional_info_tr &&
                          data.strapiBooks.additional_info_tr.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item.name}
                              </TableCell>
                              <TableCell align="center">{item.value}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={5} className={classes.bookSideGrid}>
                  {data.strapiBooks.quantity > 1 ? (
                    <Typography component="span" className={classes.bookAvability}>
                      {t("book.available")}
                    </Typography>
                  ) : (
                    <Typography component="span" className={classes.bookAvability}>
                      {t("book.unavailable")}
                    </Typography>
                  )}
                  <Container>
                    <Grid container className={classes.bookTypesIcons}>
                      <Grid item xs={6} className={`${classes.typesIcons} ${classes.greenIcon}`}>
                        <img src={FooterCategory2} alt="Electronic" />
                        <Typography variant="subtitle1" component="p">
                          {t("footer.electronic")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={`${classes.typesIcons} ${classes.purpleIcon}`}>
                        <img src={FooterCategory3} alt="Vocal" />
                        <Typography variant="subtitle1" component="p">
                          {t("footer.vocal")}
                        </Typography>
                      </Grid>
                      <Typography className={classes.bookTypestext}>
                        {t("home.heroText1")} {t("home.heroText2")}
                      </Typography>
                    </Grid>
                    <Box display="flex" justifyContent="space-evenly" className={classes.bookStoreIcons}>
                      <Box className={classes.categorySideBarStoreIcons1}>
                        <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                          <img src={StoreIcons1} alt="Google Play" />
                        </a>
                      </Box>
                      <Box className={classes.categorySideBarStoreIcons2}>
                        <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                          <img src={StoreIcons2} alt="App Store" />
                        </a>
                      </Box>
                    </Box>
                    <Paper variant="outlined" square className={classes.cartItemsCountPaper}>
                      {!data.strapiBooks.quantity && (
                        <div className={classes.outOfStock}>{t("singleBook.outOfStock")}</div>
                      )}
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={classes.countItemBox}
                      >
                        <Box>
                          <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            onClick={data.strapiBooks.quantity && handleClickBadgeIncrease}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box className={classes.cartItemsCountnumber}>{countItem.count}</Box>
                        <Box>
                          <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            onClick={data.strapiBooks.quantity && handleClickBadgeReduce}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        className={`${classes.countItemBtn1} ${classes.countItemBtns}`}
                        startIcon={<ShoppingCartIcon />}
                        onClick={data.strapiBooks.quantity && handleAddToCard}
                      >
                        {t("book.addToCard")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={data.strapiBooks.quantity && handleQuickPurchase}
                        className={`${classes.countItemBtn2} ${classes.countItemBtns}`}
                      >
                        {t("book.quickPurchase")}
                      </Button>
                    </Paper>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
            <Container className={classes.bookTabsContainer}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                className={`${classes.bookTabs} bookIntroAudio`}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {videoUrl && <Tab className={classes.bookTabTitle} label={t("book.introVideo")} />}
                <Tab className={classes.bookTabTitle} label={t("book.definitionOfBook")} />
                <Tab className={classes.bookTabTitle} label={t("book.reviews")} />

                {!isMobile && introAudioForLang ? (
                  <div className={classes.bookaudioDifentionParent}>
                    <AudioPlayer
                      className="introAudio"
                      src={introAudioForLang}
                      style={{
                        width: "200px",
                        height: "32px",
                        borderRadius: "10px",
                        margin: 12,
                      }}
                      autoPlay={false}
                      showJumpControls={false}
                      showDownloadProgress={false}
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                      customIcons={{
                        play: <PlayArrowIcon className={classes.bookPlayBtnText} />,
                        pause: <PauseIcon className={classes.bookPauseBtnText} />,
                      }}
                    />
                    <div className={classes.bookaudioDifention}>{t("book.phonemicDefinition")}</div>
                  </div>
                ) : (
                  introAudioForLang && (
                    <AudioPlayer
                      className="bookIntroAudioMobile"
                      src={introAudioForLang}
                      style={{
                        width: "200px",
                        height: "32px",
                        borderRadius: "10px",
                        margin: 12,
                      }}
                      autoPlay={false}
                      showJumpControls={false}
                      showDownloadProgress={false}
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                      customProgressBarSection={[RHAP_UI]}
                      customIcons={{
                        play: <PlayArrowIcon />,
                        pause: <PauseIcon />,
                      }}
                    />
                  )
                )}
              </Tabs>
              {introText && (
                <TabPanel value={tabValue} index={videoUrl ? 1 : 0}>
                  <Collapse in={checkedMoreLess} collapsedHeight={55}>
                    <Typography variant="subtitle1" component="span" className={classes.bookIntroText}>
                      {introText}
                    </Typography>
                  </Collapse>
                  <Button
                    checked={checkedMoreLess}
                    onChange={handleChangeMoreLess}
                    onClick={handleClickMoreLess}
                    className={classes.bookMoreLessBtn}
                  >
                    {valueMoreLess}
                  </Button>
                </TabPanel>
              )}
              {shortIntroText && (
                <TabPanel value={tabValue} index={videoUrl ? 1 : 0}>
                  <Typography variant="subtitle1" component="span" className={classes.bookIntroText}>
                    {shortIntroText}
                  </Typography>
                </TabPanel>
              )}
              <TabPanel value={tabValue} index={videoUrl ? 2 : 1}>
                {allComment !== 0 ? (
                  <>
                    {allComment.map(comment => (
                      <Paper key={comment.id}>
                        <Box display="flex" alignItems="center" p={1} m={1}>
                          <Box p={1}>
                            <Avatar
                              alt="Remy Sharp"
                              src={
                                comment.user.avater
                                  ? `${process.env.GATSBY_API_URL}` + comment.user.avater.url
                                  : "/static/images/user-picture.png"
                              }
                              className={classes.large}
                            >
                              {!comment.user.avater &&
                                (comment.user.firstName ? comment.user.firstName.charAt(0) : " ") +
                                  " " +
                                  (comment.user.lastName ? comment.user.lastName.charAt(0) : " ")}
                            </Avatar>
                          </Box>
                          <Box p={1}>
                            <Typography variant="subtitle1" component="p">
                              {comment.user ? (comment.user.firstName ? comment.user.firstName : " ") : " "}
                              {comment.user ? (comment.user.lastName ? " " + comment.user.lastName : " ") : " "}
                            </Typography>
                          </Box>
                          {comment.user.id === idForProfile && (
                            <Box p={1}>
                              <IconButton
                                aria-label="delete"
                                variant="contained"
                                color="secondary"
                                onClick={() => handleDeleteItem(comment.id)}
                                className={classes.margin}
                              >
                                <ClearIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        <Box
                          display="block"
                          alignItems="center"
                          p={1}
                          m={1}
                          bgcolor="background.paper"
                          css={{ minHeight: 100 }}
                        >
                          <Box p={1}>
                            <Typography variant="subtitle2" component="p">
                              {comment.content}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    ))}
                    {!disabledBtn && (
                      <ButtonGroup
                        color="primary"
                        aria-label="Order"
                        disabled={disabledBtn}
                        className={`${classes.margin} ${classes.bookLoadMoreBtnGroup} button`}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className={`${classes.bookLoadMoreBtn}`}
                          onClick={handleLoadMoreComments}
                        >
                          {loadingMore && <CircularProgress size={26} />}
                          {!loadingMore && t("bookList.loadMoreBtn")}
                        </Button>
                      </ButtonGroup>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1" component="p" className={classes.bookReviewText}>
                      {t("book.noReviews")}
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.bookReviewText}>
                      {t("book.firstRate")}{" "}
                      <q>
                        {i18n.language === "en" && data.strapiBooks.title}
                        {i18n.language === "ar" && data.strapiBooks.title_ar}
                        {i18n.language === "tr" && data.strapiBooks.title_tr}
                      </q>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.bookReviewText}>
                      {t("book.reviewsPolicy")}
                    </Typography>
                  </>
                )}
                {/* {jwtForProfile ? ( */}
                <Box component="div" display={jwtForProfile ? `block` : `none`}>
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12} md={6} className={classes.textCenter}>
                      <FormControl fullWidth className={`${classes.margin} ${classes.formConrolReview}`}>
                        <TextField
                          required
                          id="yourComment"
                          multiline
                          rows={4}
                          label={t("book.bookRatingComment")}
                          variant="outlined"
                          value={yourComment}
                          onChange={handleCommentChange}
                        />
                      </FormControl>
                      <FormControl fullWidth className={`${classes.margin} ${classes.formConrolReview}`}>
                        <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
                          <Grid item xs={12} className={classes.bookRatingSubmit}>
                            <Typography component="legend">{t("book.bookRatingText")}</Typography>
                            {/* <Rating
                              name="Rating"
                              defaultValue={rateValues}
                              onChange={newValue => {
                                setRateValues(newValue)
                              }}
                            /> */}
                            <Rating
                              name="simple-controlled"
                              value={rateValues}
                              onChange={(event, newValue) => {
                                setRateValues(newValue)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <ButtonGroup
                        color="primary"
                        aria-label="Order"
                        disabled={loading}
                        loading={loading.loading}
                        className={`${classes.submitBtnGroup} ${classes.margin} button`}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className={`${classes.submitBtn} ${classes.orderBookBtn}`}
                        >
                          {loading && <CircularProgress size={26} />}
                          {!loading && t("book.submitReviewBtn")}
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </form>
                </Box>
                <Box component="div" display={!jwtForProfile ? `block` : `none`}>
                  {/* ) : ( */}
                  <Container className={classes.notSignedIn}>
                    <Typography variant="subtitle1">{t("book.noPremissions")}</Typography>
                    <br />
                    <Sigin />
                  </Container>
                </Box>
                {/* // )} */}
              </TabPanel>
              {videoUrl && (
                <TabPanel value={tabValue} index={0}>
                  <iframe
                    // width="560"
                    // height="315"
                    // https://youtu.be/96VsoCU6WVc
                    src={`https://www.youtube.com/embed/${videoUrl}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={classes.introVideo}
                    id="introVideo"
                  ></iframe>
                  {/* {VideoTest} */}
                  {/* <Player
                    className={classes.introVideo}
                    src={data.strapiBooks.introVideo}
                    ref={VideoTest}
                  /> */}
                </TabPanel>
              )}
            </Container>
          </Grid>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseAddToCard}>
            <Alert onClose={handleCloseAddToCard} severity="success">
              {t("book.itemAddedToCart")}
            </Alert>
          </Snackbar>
        </Container>
        {data.strapiBooks.suggested_books.length > 0 && (
          <Container className={`${classes.fullWidth} ${classes.bookSliderContainer}`}>
            <Container className={classes.bookSliderContainerInner}>
              <Typography variant="h5" component="h2" className={classes.secTitle}>
                {t("book.suggestedProducts")}
              </Typography>
              <Slider
                {...settings}
                slidesToShow={
                  isMobile
                    ? 1
                    : isTablet
                    ? 2
                    : data.strapiBooks.suggested_books.length > 3
                    ? 5
                    : data.strapiBooks.suggested_books.length
                }
                className={`${classes.directionLtr} ${classes.sliderStyle}`}
              >
                {i18n.language &&
                  data.strapiBooks.suggested_books.map((book, key) => (
                    <SingleSlide key={book.id} slideBooks={book} openSnackbar={handleOpenSnackbar} />
                  ))}
              </Slider>
            </Container>
          </Container>
        )}
        <Container className={`${classes.fullWidth} ${classes.bookSliderContainer2}`}>
          <Container className={classes.bookSliderContainerInner2}>
            <Typography variant="h5" component="h2" className={classes.secTitle}>
              {t("book.youMayAlsoLike")}
            </Typography>
            <Slider
              {...settings}
              slidesToShow={
                isMobile
                  ? 1
                  : isTablet
                  ? 2
                  : data.allStrapiBooks.nodes.length > 3
                  ? 5
                  : data.allStrapiBooks.nodes.length
              }
              className={`${classes.directionLtr} ${classes.sliderStyle}`}
            >
              {i18n.language &&
                data.allStrapiBooks.nodes.map((book, key) => (
                  <SingleSlide key={book.strapiId} slideBooks={book} openSnackbar={handleOpenSnackbar} />
                ))}
            </Slider>
          </Container>
        </Container>
      </Container>
      <Snackbar open={openSuccessSnackbar}>
        <Alert onClose={handleCloseAddToCardSlides} severity="success">
          {t("book.itemAddedToCart")}
        </Alert>
      </Snackbar>

      <SnackbarProvider maxSnack={3}>
        <Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity="success">
            {t("book.commentSubmitted")}
          </Alert>
        </Snackbar>
      </SnackbarProvider>
    </>
  )
}
export default BookTemplate

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bookBg: {
    // backgroundImage: `url(${BookBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
    "&:before": {
      content: '""',
      background: `linear-gradient(to bottom, #d8d8d8 90%, rgb(254 254 254 / 10%), rgb(255 255 240 / 0%))`,
      // background: "linear-gradient(to bottom, #d8d8d8 6%, #fefefe, #ffffff)",
      left: 0,
      zIndex: 1,
      height: 670,
      position: "absolute",
      top: 0,
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 68%)",
        borderTopRightRadius: "10rem",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  bookRatingText: {
    color: "#018fff",
    float: "left",
    marginRight: theme.spacing(1),
  },
  bookImage: {
    // maxWidth: 300,
    // width: "60%",
    // width: 280,
    objectFit: "fill",
  },
  bookImageBorder: {
    // border: "3px solid #000",
    // borderRadius: 10,
    boxShadow: "1px 1px 7px 0px rgb(0 0 0 / 30%)",
  },
  bookInnerContainer: {
    position: "relative",
    zIndex: 3,
    maxWidth: 1440,
    marginTop: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
    },
  },
  textCenterMobile: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  bookTitle: {
    color: "#003e96",
    lineHeight: 1.5,
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      padding: "0 auto 32px",
    },
  },
  activePrice: {
    color: "#003e96",
    fontSize: 48,
    fontWeight: 600,
    order: 1,
    marginRight: "1rem",
  },
  discPrice: {
    color: "#646464",
    fontSize: 34,
    order: 2,
  },
  priceBox: {
    display: "flex",
    alignItems: "baseline",
  },
  tableLink: {
    textDecoration: "none",
    color: "#003e96",
    fontWeight: "600",
  },
  blodText: {
    fontWeight: "600",
  },
  button: {
    borderRadius: 10,
    color: "#003e96",
    fontWeight: 600,
  },
  boxButtonsLeftPadding: {
    paddingLeft: "0 !important",
    background: "transparent",
  },
  bookAvability: {
    color: "#003e96",
    fontSize: 22,
    fontWeight: 600,
  },
  bookSideGrid: {
    textAlign: "center",
    marginTop: "1rem",
  },
  typesIcons: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& img": {
      height: 60,
      width: "auto",
    },
  },
  greenIcon: {
    color: "#8cc63f",
  },
  purpleIcon: {
    color: "#93278f",
  },
  bookTypesIcons: {
    marginTop: "1rem",
    paddingLeft: 26,
    paddingRight: 26,
  },
  bookTypestext: {
    marginTop: "1rem",
    fontWeight: "bold",
  },
  categorySideBarStoreIcons1: {
    "& img": {
      height: 42,
      marginTop: 1,
    },
  },
  categorySideBarStoreIcons2: {
    "& img": {
      height: 42,
    },
  },
  bookStoreIcons: {
    marginTop: "1.5rem",
  },
  cartItemsCountPaper: {
    width: 211,
    height: 211,
    borderRadius: 6,
    position: "relative",
    margin: "2.5rem auto 0",
    backgroundColor: "#f5f5f5",
  },
  cartItemsCountnumber: {
    backgroundColor: "#ffffff",
    fontSize: "2rem",
    width: 60,
    height: 60,
    padding: "initial",
    lineHeight: "1.69",
  },
  countItemBox: {
    marginTop: "1rem",
  },
  countItemBtn1: {
    backgroundColor: "#f7931e",
    "&.MuiButton-contained:hover": {
      backgroundColor: "#018fff",
    },
  },
  countItemBtn2: {
    backgroundColor: "#018fff",
    "&.MuiButton-contained:hover": {
      backgroundColor: "#f7931e",
    },
  },
  countItemBtns: {
    color: "#fff",
    marginTop: "1rem",
    width: "75%",
    fontSize: ".8rem",
  },
  bookIntroText: {
    color: "#003e96",
  },
  bookTabs: {
    marginTop: "3rem",
    "&>div>.MuiTabs-flexContainer": {
      borderBottom: "2px solid #ddd",
    },
  },
  bookMoreLessBtn: {
    color: "#018fff",
    float: "right",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  bookSliderContainer: {
    backgroundColor: "#f5f5f5",
  },
  bookSliderContainerInner: {
    paddingTop: "3rem",
    paddingBottom: ".8rem",
  },
  sliderStyle: {
    marginBottom: "1rem",
  },
  directionLtr: {
    flip: false,
  },
  slide: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      maxWidth: 165,
      outlineWidth: 0,
      textAlign: "center",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      border: "3px solid #000",
      borderRadius: 10,
      marginBottom: "1rem",
    },
    "& h3": {
      marginTop: "1rem",
      textDecoration: "none",
      color: "#003e96",
    },
  },
  secTitle: {
    textAlign: "center",
    color: "#003e96",
  },
  bookSliderContainer2: {
    marginTop: "2.8rem",
    marginBottom: "1rem",
  },
  bookTabTitle: {
    fontSize: 16,
    "& .MuiTab-wrapper": {
      fontWeight: 600,
    },
  },
  bookReviewText: {
    color: "#003e96",
  },
  formConrolReview: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiTextField-root": {
      width: "100%",
      backgroundColor: "#f5f5f5",
    },
  },
  orderBookBtn: {
    height: 40,
    borderRadius: 10,
  },
  textCenter: {
    textAlign: "center",
    "& > .MuiTypography-root": {
      marginBottom: theme.spacing(2),
      fontSize: 26,
    },
  },
  submitBtn: {
    width: 90,
  },
  submitBtnGroup: {
    float: "right",
    marginBottom: "3rem",
  },
  bookRatingSubmit: {
    textAlign: "left",
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  bookLoadMoreBtn: {
    height: 40,
    borderRadius: 10,
    width: 126,
    maxWidth: 126,
  },
  bookLoadMoreBtnGroup: {
    float: "right",
  },
  outOfStock: {
    backgroundColor: "rgba(0,0,0,.65)",
    position: "absolute",
    left: 0,
    top: 0,
    color: "#fff",
    zIndex: 9,
    fontWeight: "600",
    padding: "45.4% 31.79%",
    width: "100%",
  },
  currencyIcon: {
    width: 14,
    marginBottom: "-2px",
    border: "0 !important",
    borderRadius: "0 !important",
  },
  introVideo: {
    maxWidth: "80% !important",
    paddingTop: "45% !important",
  },
  tableContainer: {
    marginTop: "1rem",
    border: "2px solid #000",
    borderRadius: 6,
  },
  table: {
    "& *": {
      border: 0,
    },
    "& .MuiTableCell-body": {
      color: "#003e96",
      display: "block",
    },
    "& .MuiTableRow-root th": {
      width: "154px",
      fontWeight: 600,
      float: "left",
    },
    "& tr .MuiTableCell-body ": {
      borderColor: "#000",
      borderStyle: "solid",
    },
    "& tr:not(:last-child) td.MuiTableCell-body": {
      // borderLeftWidth: 2,
      borderBottomWidth: 2,
      fontWeight: "600",
    },
    // "& tr:last-child td.MuiTableCell-body": {
    //   // borderLeftWidth: 2,
    // },
    "& tr:not(:last-child) th.MuiTableCell-body": {
      borderRightWidth: 2,
      borderBottomWidth: 2,
    },
    "& tr:last-child th.MuiTableCell-body": {
      borderRightWidth: 2,
    },
    "& tr *": {
      fontWeight: "600",
    },
  },
  bookaudioDifentionParent: {
    position: "relative",
  },
  bookaudioDifention: {
    left: 18,
    bottom: 18,
    position: "absolute",
    fontSize: 14,
    fontWeight: 600,
    color: "#fff",
    width: "70%",
  },
  introVideo: {
    width: "100%",
    height: "600px",
  },
  bookTabsContainer: {
    marginBottom: "32px",
  },
}))

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// comments {
//   book
//   content
//   id
//   status
//   user
// }

export const query = graphql`
  query BookTemplate($id: String, $firstCategories: Int!) {
    strapiBooks(id: { eq: $id }) {
      id
      slug
      ISBN
      cover
      cover_ar
      cover_tr
      title
      title_ar
      title_tr
      width
      height
      weight
      rating
      isFree
      subject
      subject_ar
      subject_tr
      quantity
      strapiId
      introText
      introText_ar
      introText_tr
      pagesNumber
      publicationYear
      introVideo
      introVideo_tr
      introVideo_ar
      image {
        publicURL
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed_withWebp
            srcWebp
          }
        }
      }
      book_items {
        id
        price
        title
        type
        discountPrice
      }
      author {
        id
        slug
        firstName
        firstName_ar
        firstName_tr
        lastName
        lastName_ar
        lastName_tr
        description
        image {
          publicURL
        }
      }
      categories {
        id
        slug
        title
        title_tr
        title_ar
      }
      publisher {
        id
        name
        slug
        name_tr
        name_ar
      }
      introAudio {
        publicURL
      }
      introAudio_ar {
        publicURL
      }
      introAudio_tr {
        publicURL
      }
      additional_info {
        id
        name
        value
      }
      additional_info_ar {
        id
        name
        value
      }
      additional_info_tr {
        id
        name
        value
      }
      investigator {
        firstName
        firstName_ar
        firstName_tr
        lastName
        lastName_ar
        lastName_tr
      }
      suggested_books {
        id
        slug
        ISBN
        cover
        cover_ar
        cover_tr
        title
        title_ar
        title_tr
        width
        height
        weight
        rating
        isFree
        subject
        subject_tr
        subject_ar
        quantity
        introText
        introText_ar
        introText_tr
        pagesNumber
        publicationYear
        image {
          publicURL
          childImageSharp {
            fixed(width: 169, height: 244) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        introAudio {
          publicURL
        }
        introAudio_ar {
          publicURL
        }
        additional_info {
          id
          name
          value
        }
        additional_info_ar {
          id
          name
          value
        }
        additional_info_tr {
          id
          name
          value
        }
      }
    }
    allStrapiAuthors {
      nodes {
        id
        firstName
        lastName
        slug
        description
        image {
          publicURL
        }
        books {
          id
          slug
          ISBN
          title
          title_ar
          title_tr
          rating
          quantity
          image {
            publicURL
          }
        }
      }
    }
    allStrapiBooks(
      limit: 10
      filter: { categories: { elemMatch: { id: { eq: $firstCategories } } } }
      sort: { order: DESC, fields: id }
    ) {
      nodes {
        id
        slug
        ISBN
        title
        title_ar
        title_tr
        rating
        strapiId
        quantity
        image {
          publicURL
          childImageSharp {
            fixed(width: 176, height: 244) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        book_items {
          id
          price
          title
          type
        }
      }
    }
    allStrapiCategories {
      nodes {
        id
        strapiId
        slug
        title
        title_ar
        title_tr
        backgroundColor
        image {
          publicURL
        }
        parent_cat {
          id
          slug
          title
          title_ar
          title_tr
          image {
            publicURL
          }
        }
        sub_cats {
          id
          slug
          title
          title_ar
          title_tr
          parent_cat
          image {
            publicURL
          }
        }
      }
    }
  }
`
